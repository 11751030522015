// 500px
export const Phablet = `min-width: 31.25em`;

// 767px
export const TabletV = `min-width: 48em`;

// 992px
export const TabletH = `min-width: 62em`;

// 1200px
export const Laptop = `min-width: 75em`;

// 1680px
export const Desktop = `min-width: 105em`;
