export interface ThemeInterface {
  primaryColor: string;
  secondaryColor: string;
}

const theme: ThemeInterface = {
  primaryColor: 'f26a4b',
  secondaryColor: '#fff'
};

export default theme;
